import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="qrcode-style-preview"
export default class extends Controller {
  static targets = [ "image", "form", "bgTransparentButton", "bgColorInput" ]

  static values = {
    url: String
  }

  static classes = [ "loading" ]

  connect() {
    this.updateBgColor()
  }

  async update() {
    this.imageTarget.classList.add(...this.loadingClasses)
    const formData = new FormData(this.formTarget)
    formData.delete("_method")
    const response = await post(this.urlValue, {
      body: formData,
    })

    if (response.ok) {
      response.response.blob().then(blob => {
        const url = URL.createObjectURL(blob)
        this.imageTarget.src = url
        this.imageTarget.classList.remove(...this.loadingClasses)
      })
    }
  }

  updateBgColor() {
    if (this.bgTransparentButtonTarget.checked) {
      this.bgColorInputTarget.disabled = true
    } else {
      this.bgColorInputTarget.disabled = false
    }
  }
}
