import QRCodeStyling from 'qr-code-styling/lib/qr-code-styling';
import { throttle } from 'lodash';

const QR_SIZE = 512;

const throttledUpdate = throttle((instance, options) => {
  instance.update({
    dotsOptions: {
      color: options.fg_color,
      type: mapDotsStyle(options.dots_style),
    },
    backgroundOptions: {
      color: options.bg_color,
    },
    cornersSquareOptions: {
      type: mapCornerSquareStyle(options.position_style),
      color: options.fg_color,
    },
    cornersDotOptions: {
      type: mapCornerDotsStyle(options.position_style),
      color: options.fg_color,
    },
    margin: (QR_SIZE * options.quiet_modules) / 40,
  });
}, 500);

function mapDotsStyle(style) {
  switch (style) {
    case 'square_dots':
      return 'square';
    case 'circle':
      return 'dots';
    case 'rounded':
      return 'rounded';
    default:
      return 'square';
  }
}

function mapCornerSquareStyle(style) {
  switch (style) {
    case 'rounded':
      return 'extra-rounded';
    case 'circle':
      return 'dots';
    default:
      return 'square';
  }
}

function mapCornerDotsStyle(style) {
  switch (style) {
    case 'rounded':
      return 'square';
    case 'circle':
      return 'dots';
    default:
      return 'square';
  }
}

export default (name, short_url, options = {}) => ({
  name: name,
  short_url: short_url,
  options: {
    fg_color: '#000000',
    bg_color: '#ffffff',
    dots_style: 'square',
    position_style: 'square', // cirlce
    margin: 40,
    quiet_modules: 4,
    ...options,
  },
  init() {
    const qrCode = new QRCodeStyling({
      width: QR_SIZE,
      height: QR_SIZE,
      data: short_url,
      qrOptions: {
        errorCorrectionLevel: 'H',
      },
      margin: (this.options.quiet_modules * QR_SIZE) / 40,
      dotsOptions: {
        color: this.options.fg_color,
        type: mapDotsStyle(this.options.dots_style),
      },
      cornersSquareOptions: {
        type: mapCornerSquareStyle(this.options.position_style),
        color: this.options.fg_color,
      },
      cornersDotOptions: {
        type: mapCornerDotsStyle(this.options.position_style),
        color: this.options.fg_color,
      },
      backgroundOptions: {
        color: this.options.bg_color,
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 20,
      },
    });
    this.$refs.container.replaceChildren();
    qrCode.append(this.$refs.container);
    this.$watch('options', (newOptions) => {
      throttledUpdate(qrCode, newOptions);
    });
    this.instance = qrCode;
  },

  download() {
    this.instance.download({ name: this.name, extension: 'png' });
  },
});
