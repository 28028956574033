import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-selector"
export default class extends Controller {
  static targets = [ "frame" ]

  connect() {
  }

  select(event) {
    this.frameTarget.src = event.currentTarget.value
  }
}
