import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-field"
export default class extends Controller {
  static targets = [ "image", "fileInput", "removeCheckbox" ]

  static values = {
    defaultUrl: String
  }

  connect() {
  }

  update() {
    const file = this.fileInputTarget.files[0]
    const reader = new FileReader()

    reader.onload = (event) => {
      this.imageTarget.src = event.target.result
    }

    reader.readAsDataURL(file)

    this.removeCheckboxTarget.checked = false
    this.element.classList.add("attached")
  }

  remove() {
    this.imageTarget.src = this.defaultUrlValue
    this.fileInputTarget.value = null
    this.element.classList.remove("attached")
  }
}
