import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-edit"
export default class extends Controller {
  static targets = [ "view", "edit", "input" ]

  connect() {
  }

  edit() {
    this.viewTarget.classList.add("hidden")
    this.editTarget.classList.remove("hidden")
    if (this.hasInputTarget) {
      const end = this.inputTarget.value.length
      this.inputTarget.setSelectionRange(end, end)
      this.inputTarget.focus()
    }
  }

  view() {
    this.viewTarget.classList.remove("hidden")
    this.editTarget.classList.add("hidden")
  }
}
