import { Controller } from "@hotwired/stimulus"
import { set } from "lodash"

// Connects to data-controller="flash"
export default class extends Controller {
  static classes = [ "remove" ]

  connect() {
    setTimeout(() => {
      this.remove()
    }, 5000)
  }

  remove() {
    this.element.classList.add(this.removeClass)
    this.element.addEventListener("transitionend", () => {
      this.element.remove()
    })
  }
}
