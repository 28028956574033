import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-field"
export default class extends Controller {
  static targets = [ "input", "label" ]

  connect() {
  }

  update() {
    const file = this.inputTarget.files[0]
    if (file) {
      this.labelTarget.textContent = file.name
    }
  }
}
